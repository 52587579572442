import React from 'react';

import { adlessQuery, adlessCommand, commandResponse } from '.';
import { ListResponse, BrowseResponse, DescribeRequest, DescribeResponse, SubscribeRequest, SubscribeResponse, CancelRequest, CancelResponse, DescribePlanRequest, DescribePlanResponse, ServiceDomainsResponse, ServiceDomainsRequest } from 'api/gen/subscription'

export default {
    list: () => adlessQuery<ListResponse>("/v1/subscription/list"),
    browse: () => adlessQuery<BrowseResponse>("/v1/subscription/browse"),
    describe: (req: DescribeRequest) => adlessCommand<DescribeResponse>("/v1/subscription/describe", req),
    describe_plan: (req: DescribePlanRequest) => adlessCommand<DescribePlanResponse>("/v1/subscription/describe/plan", req),
    subscribe:  (req: SubscribeRequest) => adlessCommand<SubscribeResponse>("/v1/subscription/subscribe", req),
    cancel: (req: CancelRequest) => adlessCommand<CancelResponse>("/v1/subscription/cancel", req),
    domains: (req: ServiceDomainsRequest) => adlessCommand<ServiceDomainsResponse>("/v1/subscription/services/domains", req),
}