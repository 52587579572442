import React, { ReactNode, useContext } from 'react';

import { ImageURISource, useWindowDimensions, View as RNView, ViewStyle } from 'react-native';
import { Button as RNButton, ButtonProps, Card as RNCard, Image, ImageProps, ListItem as RNListItem, ListItemProps, Text as RNText, TextProps, ThemeContext as TNThemeContext } from 'react-native-elements';
import { Location } from 'Routing';

export const ThemeContext = TNThemeContext;

type Props = {
    children?: any,
    style?: ViewStyle,
    center?: boolean,
    wide?: boolean
    gap?: number
}

type PageProps = {
    children?: any,
    style?: ViewStyle,
}

export const Row = ({ children, style, center, gap }: Props) => {
    return (
        <RNView style={{
            maxWidth: "100%",
            flexGrow: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            gap: gap ?? 0,
            textAlign: center ? "center" : "justify",
            justifyContent: center ? "center" : "flex-start",
            alignItems: "stretch",
            ...style,
        }}>{children}</RNView>
    );
};

export const Column = ({ children, style, center, gap }: Props) => {
    return (
        <RNView style={{
            maxWidth: "100%",
            flexGrow: 1,
            flexDirection: "column",
            flexWrap: "wrap",
            gap: gap ?? 0,
            justifyContent: "flex-start",
            textAlign: center ? "center" : "justify",
            alignItems: center ? "center" : "stretch",
            ...style,
        }}>{children}</RNView>
    );
};

type DividerProps = {
    orientation: "horizontal" | "vertical",
    width: number,
    color: string | undefined
}

export const Divider = ({ orientation, width, color }: DividerProps) => {
    if (orientation === "horizontal") {
        return <RNView style={{
            width: "100%",
            height: width,
            backgroundColor: color,
        }}></RNView>
    } else {
        return <RNView style={{
            width: width,
            height: "100%",
            backgroundColor: color,
        }}></RNView>
    }
};

export const FullScreenPage = ({ children, style }: PageProps) => {
    const { theme } = useContext(ThemeContext)

    return (
        <RNView style={{
            maxWidth: "100%",
            minHeight: "calc(100vh - 75px)",
            ...style,
        }}>{children}</RNView>
    )
};

export const PrimaryColorPage = ({ children, style }: Props) => {
    const { theme } = useContext(ThemeContext)

    style = {
        ...theme.Header,
        ...style,
    }

    return (
        <FullScreenPage style={style}>{children}</FullScreenPage>
    )
};

export const View = ({ children, style, center }: Props) => {
    return (
        <RNView style={{
            maxWidth: "100%",
            justifyContent: center !== undefined ? "center" : "flex-start",
            textAlign: center ? "center" : "justify",
            ...style,
        }}>{children}</RNView>
    );
};

export const Section = ({ children, style, center, wide }: Props) => {
    return (
        <View style={{
            width: wide ? '100vw' : '80vw',
            alignSelf: 'center',
            paddingBottom: 20,
            marginHorizontal: 'auto',
            marginTop: 25,
            ...style,
        }} center={center}>{children}</View>
    );
};

export const SidebarMenu = ({ children, style }: Props) => {
    const { theme } = useContext(ThemeContext)

    const d = useWindowDimensions()

    const decoratedChildren = React.Children.map(children, child => {
        let active = child.props.to?.split("/")[1] === Location().pathname.split("/")[1] && child.props.to?.split("/")[2] === Location().pathname.split("/")[2]

        let style = {
            ...(child.props.style || {}),
            padding: 10,
            textAlign: "right",
            width: "calc(100%-20)",
            color: theme.colors?.secondary,
            backgroundColor: theme.colors?.primary,
            textDecoration: "none",
        }

        if (active) {
            style.backgroundColor = theme.colors?.secondary
            style.color = theme.colors?.primary
        }

        return React.cloneElement(child, {
            style: style,
            active: active
        })
    });


    if (d.width < 850) {
        return (
            <Row style={{ width: "100%", backgroundColor: theme.colors?.primary, height: "fit-content", overflow: 'scroll', flexWrap: 'nowrap' }}>
                {React.Children.map(decoratedChildren, child => React.cloneElement(child, {
                    style: {
                        ...(child.props.style || {}),
                        marginTop: 10,
                        height: 30,
                    }
                }))}
            </Row>
        )
    }

    return (
        <View style={{
            ...style,
        }}>{decoratedChildren}</View>
    );
};


export const Card = (props: any) => {
        const styleProp = props.containerStyle || {}

        if(!!props.disabled) {
            styleProp.pointerEvents = "none"
            styleProp.filter = "blur(2px)"
        }

        return <div onClick={props.onPress ?? undefined}><RNCard containerStyle={{
            minWidth: props?.narrow? "300px" : 'fit-content',
            maxWidth: props?.narrow? "300px" : "100%",
            borderRadius: 5,
            overflow: "hidden",
            borderWidth: props.focus ? 1 : 0,
            borderColor: "#bdc3ff",
            cursor: props.onPress ? "pointer":"default",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            color: "#000",
            textAlign: props?.center? "center" : "left",
            ...styleProp,
        }} wrapperStyle={{...(props?.wrapperStyle || {})}}>{props.children}</RNCard></div>
    }

Object.assign(Card, {
    Title: RNCard.Title,
    Divider: RNCard.Divider
})

type TextHelperProps = {
    align?: "center" | "justify"
    padded?: boolean 
    error?: boolean
    center?: boolean
    bold?: boolean
}

export const Text = (props: TextProps | TextHelperProps) => {
    let extprops = {
        style: {},
        ...props
    }
    
    if (!!extprops.align) {
        extprops.style.textAlign = extprops.align
    }

    if (!!extprops.padded) {
        extprops.style.paddingTop = "2em"
    }

    if (!!extprops.error) {
        extprops.style.color = "red"
    }

    if (!!extprops.small) {
        extprops.style.fontSize = "0.8em"
    }

    if (!!extprops.center) {
        extprops.style.textAlign = "center"
    }

    if (!!extprops.bold) {
        extprops.style.fontWeight = "bold"
    }
    
    return <RNText {...extprops}></RNText>
}

export const Spacer = ({height = 30}) => {
    return <div style={{width: "100%", height: height}}></div>
}

export const Notice = ({ children, style }: Props) => {
    return <div style={{ textAlign: "center", backgroundColor: "#89cff0", color: "#000", padding: 10, ...style }}>{children}</div>
}

export const Warning = ({ children, style }: Props) => {
    return <div style={{ textAlign: "center", backgroundColor: "#FFFCC9", color: "#000", padding: 10, ...style }}>{children}</div>
}

export const Failure = ({ children, style }: Props) => {
    return <div style={{ textAlign: "center", backgroundColor: "#FF7779", color: "#000", padding: 10, ...style }}>{children}</div>
}

export const Success = ({ children, style }: Props) => {
    return <div style={{ textAlign: "center", backgroundColor: "#D1F3C5", color: "#000", padding: 10, ...style }}>{children}</div>
}

export class StaticImage extends React.Component<ImageProps> {
    render(): ReactNode {
        const s = (this.props.source as ImageURISource)
        if (s.uri) {
            s.uri = "https://static.adless.net" + s.uri
           return <Image {...{...this.props, source: s}}></Image>
        }

        return <Image {...this.props}></Image>
    }
}

export const Button = (props: ButtonProps) => RNButton(props)

export const ListItem = (props: ListItemProps) => <RNListItem {...props}><Text>{props.children}</Text></RNListItem>

Object.assign(ListItem, {
    Accordion: RNListItem.Accordion,
    Chevron: RNListItem.Chevron,
    Content: RNListItem.Content,
    Title: RNListItem.Title,
})