// @generated by protobuf-ts 2.5.0
// @generated from protobuf file "c_errors.proto" (package "common", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message common.ErrorDetails
 */
export interface ErrorDetails {
    /**
     * @generated from protobuf field: common.ErrorCause cause = 1;
     */
    cause: ErrorCause;
}
/**
 * @generated from protobuf enum common.ErrorCause
 */
export enum ErrorCause {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: INVALID_PAYMENT_SOURCE = 1;
     */
    INVALID_PAYMENT_SOURCE = 1,
    /**
     * @generated from protobuf enum value: MISSING_PERSONAL_INFORMATION = 2;
     */
    MISSING_PERSONAL_INFORMATION = 2,
    /**
     * @generated from protobuf enum value: MISSING_PAYMENT_SOURCE = 3;
     */
    MISSING_PAYMENT_SOURCE = 3,
    /**
     * @generated from protobuf enum value: AUDIT_FAILED = 1000;
     */
    AUDIT_FAILED = 1000
}
// @generated message type with reflection information, may provide speed optimized methods
class ErrorDetails$Type extends MessageType<ErrorDetails> {
    constructor() {
        super("common.ErrorDetails", [
            { no: 1, name: "cause", kind: "enum", T: () => ["common.ErrorCause", ErrorCause] }
        ]);
    }
    create(value?: PartialMessage<ErrorDetails>): ErrorDetails {
        const message = { cause: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ErrorDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ErrorDetails): ErrorDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.ErrorCause cause */ 1:
                    message.cause = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ErrorDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.ErrorCause cause = 1; */
        if (message.cause !== 0)
            writer.tag(1, WireType.Varint).int32(message.cause);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.ErrorDetails
 */
export const ErrorDetails = new ErrorDetails$Type();
