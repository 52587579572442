
import { Card, Notice, Row, Section, Text } from 'components/Layout';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'react-native-elements';
import { EmailLink, ExternalLink, Link, SupportEmailLink } from 'Routing';

export const Terms = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()

    return (
        <Section>
            <Text h1>Standard Terms of Service</Text>

            <Text>Effective from June 9th 2022 and until replaced by a newer revision.</Text>

            <Text>We may change the terms by posting revised terms on <Link to="/terms">adless.net/terms</Link> or in linked terms and policies (including, but not limited to our <Link to="/privacy">Privacy Policy</Link>). The new terms will be effective starting on the date indicated in the updated document(s), as applicable, and apply to any continued or new use of the Service. We may change the Service or any features of the Service at any time, and we may discontinue the Service or any features of the Service at any time.</Text>

            <Text h2>Definitions & Scope</Text>

            <Text>We collectively refer to Adless ApS, any group company and vendors acting on behalf of Adless ApS, or any group company as "Adless", "we", "us", "our".</Text>

            <Text>We refer to any website, service or product offered by Adless may be referred to as "the Service", "the Product", "our services", "our products".</Text>

            <Text>We collectively refer to to accessing, signing up for, contracting with, or otherwise interacting with our services as "using".</Text>

            <Text>By creating an account or otherwise contracting with Adless, or by otherwise accessing our services, we may refer to you as a customer or member.</Text>

            <Text>These terms as well as all the linked terms and policies in this document collectively form the Terms of the Agreement.</Text>
            <Text>Some parts of the Service have additional terms that you must accept in order to user those parts of the Services. These include, but are not limited to:
                <ul>
                    <li>The <Link to="/terms/earner">Standard Earner Terms</Link> covers the additional terms that relates to earning with Adless.</li>
                    <li>The <Link to="/terms/influencer">Standard Influencer Terms</Link> covers the additional terms that relates to earning as an influencer with Adless.</li>
                    <li>The <Link to="/privacy">Privacy & Cookie Policy</Link> covers privacy and cookies.</li>
                </ul>
            </Text>

            <Text h2>About Us</Text>

            <Text>The company details of Adless Aps are: <br /><br />Adless ApS<br />Bygaden 7<br />8520 Lystrup<br />Denmark<br /><br />Adless ApS is registered in Denmark and identified by VAT ID 43129287. We can be contacted by e-mailing <SupportEmailLink />.</Text>

            <Text h2>1. General</Text>
            <Text>By using our services, you are agreeing to be bound by these terms and conditions of service and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from using our services.</Text>
            <Text>By using our our services, you are also agreeing to be bound by our <Link to="/privacy">Privacy Policy</Link> and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from using the Service.</Text>
            <Text>The materials offered by and contained in our services is protected by copyright and trade mark law.</Text>

            <Text h2>2. Account</Text>
            <Text>When you create an Adless account and thereby agree to these terms, the Agreement between you and Adless is formed, and the term of the Agreement (the “Term”) will begin. The Term will continue for as long as you have an Adless account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first. If you sign up for an account on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.</Text>
            <Text>You can terminate your Adless account at any time using the <Link to="/account">Account Panel</Link> or by e-mailing <SupportEmailLink />.</Text>
            <Text>In order to enter into the Agreement, you must:
                <ol>
                    <li>Be at least eighteen (18) years old and able to enter into contracts.</li>
                    <li>Complete the account registration process with true, up-to-date information and keep all information up to date as long as you remain a Member.</li>
                    <li>Agree to these terms and conditions, as well as other terms and conditions linked in these terms that form part of the the Agreement.</li>
                    <li>Agree to any additional terms and conditions presented when signing up for for parts of the Service that have additional terms and conditions.</li>
                    <li>Not be based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S. government or EU embargo, or that has been designated by the U.S. government or EU as a “terrorist-supporting” country.</li>
                    <li>Not be listed on any U.S. government or EU list of prohibited or restricted persons.</li>
                    <li>Not be based in Russia.</li>
                </ol>
            </Text>

            <Text>By entering into the Agreement, you represent and warrant that you meet all the requirements listed above, and that you won't use our services in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise. We may refuse service, close accounts, and change eligibility requirements at any time.</Text>

            <Text>If you during the Term of our Agreement no longer meet our eligibility criteria you must make us aware of this immediately by contacting <SupportEmailLink />.</Text>

            <Text>You or Adless may terminate the Agreement at any time and for any reason. You may do so by terminating your Adless account or we may do so by giving notice to you that we are terminating the Agreement. We may suspend our services to you at any time, with or without cause. We reserve the right not to refund or reimburse you in any situation. If your account is inactive for 24 or more months, we may terminate your account and you won't be entitled to a refund for any account balance. Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it.</Text>

            <Text h2>3. Products & Payment</Text>

            <Text>Adless offers multiple services to multiple target audiences. This section covers the Services targeted at consumers. Earner services are covered in the  <Link to="/terms/earner">Earner Terms</Link>.</Text>

            <Text h3>Subscriptions</Text>

            <Text>Adless offers subscriptions, where Adless acts as the subscription management platform and third-party Earners deliver the "benefits" included in the subscription (see the <Link to="/terms/earner">Earner Terms</Link> for information about Earners).</Text>

            <Text>Subscriptions give access to "benefits" clearly stated for each individual subscription. The benefit is provided on third-party website (or via other relevant channels) managed by the Earner.</Text>

            <Text>When subscribing to an Adless subscription, you are entering into a contract with Adless ApS and are a customer of Adless.</Text>

            <Text>You may at any time cancel your subscription from the <Link to="/account">Account Panel</Link>. If cancelled, the subscription will continue until the renewal date but not be renewed. Renewal date and cancellation status is available in the <Link to="/account">Account Panel</Link>.</Text>

            <Text h4>Subscription Benefits</Text>

            <Text>Adless does not guarantee specific Earners to offer or remain offering a given benefit, as Earners can join and leave at any time.</Text>

            <Text>A benefit is not guaranteed to be available for all content managed by an Earner offering the benefit, nor for all content on websites (or other channels) where some of its content is offered with the benefit.</Text>

            <Text>Changes to contributing Earners or benefits offered by a subscription does not entitle you to any compensation or rights to cancel the subscription outside your normal rights as outlined in these Terms.</Text>

            <Text h4>Payment</Text>

            <Text>You must register a supported and valid credit or debit card to enter into paid subscriptions. You must keep your card informations up-to-date. If you fail to do so, or if charging fails for other reasons (like insufficient balance), paid subscriptions will not be renewed and will automatically be suspended at the date of renewal. It the issue is not resolved they will eventually be cancelled, after which you may not be able to restore your subscriptions at the same terms.</Text>

            <Text>There are no fees associated with suspended or cancelled subscriptions.</Text>

            <Text>Payment happens using the card on file. We may a) charge your card directly, b) use the card to top up the account balance to cover the cost of ongoing or new subscriptions for one payment period at a time, or c) charge the remaining amount due not covered by your account balance directly to the card.</Text>

            <Text>Price and frequency of charging varies between the subscriptions and will be clearly indicated when subscribing. All prices on this website are displayed with a clear indication of the currency and all prices include VAT.</Text>

            <Text>The charge for the first period of a subscription is charged immediately. The next charge will be charged on the renewal date for the subscription, which is displayed when you make the subscription and available at the <Link to="/account">Account Panel</Link>.</Text>


            <Text>Invoices are delivered to you in your <Link to="/account">Account Panel</Link>.</Text>

            <div id="refunds"></div>
            <Text h4>Refunds & Delivery</Text>
            <Text>Subscriptions are delivered immediately and immediately take effect. This means that the 14 day right to refund provided in some jurisdictions, as well as any other refund entitlement, lapses.</Text>

            <Text>Adless offers no refunds except on a case-by-case basis decided at our sole discretion. To request a refund or raise an issue, contact <SupportEmailLink />.</Text>

            <Text h2>4. Disclaimer</Text>
            <Text>The Service as well as other materials and services provided by Adless are provided "as is". Adless makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Adless does not make any representations concerning the accuracy or reliability of the use of the materials in our products or otherwise relating to such materials or any sites linked from our products.</Text>

            <Text h2>5. Limitations</Text>

            <Text>Adless and its suppliers will not be hold accountable for any damages that will arise with the use or inability to use our services, even if Adless or an authorize representative has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you. Adless cannot be held responsible for any losses incurred to you under any circumstances, including, but not limited to, the unavailability of our services.</Text>

            <Text>To the maximum extent permitted by law, you acknowledge and agree that you assume full responsibility for any loss that results from your use of the Service.</Text>

            <Text>We are not responsible for the behavior of any third parties, agencies, linked websites, or other Members.</Text>

            <Text>Your violation of these Terms may cause irreparable harm to us and our Team. Therefore, we have the right to seek injunctive relief or other equitable relief if you violate these Terms (meaning we may request a court order to stop you).</Text>

            <Text h2>6. Revisions and Errata</Text>

            <Text>The materials appearing in the Service (including the website) and on other communication channels (including, but not limited to, social media) may include legal, technical, typographical, or photographic errors. Adless will not promise that any of the materials in this Website are accurate, complete, or current. Adless may change these materials at any time without notice. Adless does not make any commitment to update the materials.</Text>

            <Text h2>7. Privacy</Text>

            <Text>Your privacy is extremely important to us so we dedicated a separate page to make this easy to understand, so please read our <Link to="/privacy">Privacy Policy</Link>.</Text>

            <Text h2>8. Abuse & Fraud</Text>

            <Text>The Service must at all times be used in line with the available instructions.</Text>

            <Text>It is not allowed to call the Adless API, directly or in any indirect way, in a way that deviates from that of the official clients without a written exception from Adless.</Text>

            <Text>Behaviour will be determined as abusive at the sole discression of Adless.</Text>

            <Text>In case of abusive behaviour, we may issue a warning to, suspend, or terminate your account.</Text>

            <Text>Fraudulent behavior may result in account funds being withheld or seized by Adless. This does not limit our right to pursue additional legal actions.</Text>

            <Text h2>9. Governing Law</Text>

            <Text>Any disputes or claims related to the Service or Adless shall be governed by the laws of Denmark without regards to its conflict of law provisions and must be carried out in the Danish judicial system exclusively.</Text>
        </Section>
    );
};

export const Privacy = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()

    return (
        <>
            <Notice>These terms are a part of the <Link to="/terms">Standard Terms</Link>, where some formal definitions omitted here for simplicity are also available.</Notice>

            <Section>
                <Text h1>Privacy Policy</Text>
                <Text>
                    Your privacy is very important to us. We operate following principles. Below, we will go through the details of how we implement each principle.
                </Text>
                <Row center>
                    <Card narrow center>
                        <Text h2>We do not sell or share your data.</Text></Card>
                    <Card narrow center>
                        <Text h2>We are careful with your data.</Text></Card>
                    <Card narrow center>
                        <Text h2>We only expose aggregated data for legitimate purposes.</Text></Card>
                    <Card narrow center>
                        <Text h2>We only use necessary cookies.</Text>
                    </Card>
                </Row>
            </Section>

            <Section>
                <Text h2>What data do we collect and why?</Text>
                <Text>We handle two categories of data in order to offer our services:</Text>
                <Text h3>User Data</Text>
                <Text>We collect the data you actively provide to us when you:
                    <ul>
                        <li>Create an account (email, password)</li>
                        <li>Sign up for services (consent)</li>
                        <li>Provice account and payment information (billing information, credit card information stored with out payment service provider, payment token received from our payment service provider)</li>
                        <li>Sign up for our newsletter (email)</li>
                    </ul>
                </Text>
                <Text>We need this information to provide the Service and do not share it with other Members except indirectly in anonymised, aggregated form (see below).</Text>
                <Text>For some actions, we also store audit information which may include additional data, like your IP address, to detect fraud and to keep record of actions.</Text>

                <Text h3>Usage Data</Text>
                <Text>When visiting content created by an Earner, we collect information about the page view which may include things that are generally available from a normal web request. This includes things like the address you are visiting, where you are visiting from (your IP) and how you got to the site (the referer). If you are an authenticated Member, we associate this information with the page view (but only share this indirectly in anonymised, aggregated form with the Earner, see below). We do this to compensate the Earner for the consumed content as set out in the <Link to="/terms/earner">Earner Terms</Link>.</Text>
            </Section>

            <Section>
                <Text h2>1. We do not sell or share your data</Text>
                <Text>We do not share any personal information except with third-party Data Processors that are necessary to offer the Service as described in this policy.</Text>
            </Section>

            <Section>
                <Text h2>2. We are careful with your data</Text>
                <Text>We store your data safely in the cloud and in regions that match what you requested when you created your account. By default we process and store data in the European Union.</Text>
                <Text>We have tight access control over who can access our production environment and thus your data.</Text>
            </Section>

            <Section>
                <Text h2>3. We only expose aggregated data to Earners</Text>
                <Text>Earners need to know why they receive the compensation they do. To do that, we provide them with Usage Data that is aggregated not to be personally identifiable.</Text>
                <Text>If you are an authenticated Member, we may additionally use this information along with information about what subscriptions you subscribe to in order to provide aggregated data to Earners about what services they could benefit from offering.</Text>
                <Text>To make it easier for you to evaluate if this is acceptable for you, here is an example of how we might present this information to the Earner. This is just an example and additional data and visualisations will be available to Earners. Sign up as an earner for a complete overview of currently available visualisations.</Text>
                <Card containerStyle={{ alignSelf: "center" }}>
                    <Card.Title>Example: Monthly Earner Report</Card.Title>
                    <ul>
                        <li>You had 150,000 unique monthly active Adless users and 25,000,000 page views not from an Adless user out of 75,000,000 total page views</li>
                        <li>You earned $75,000 for 7,500,000 eligible page views for premium content access</li>
                        <li>If you also had remove ads, you could have earned $100,000 for 10,000,000 eligible page views</li>
                        <li>Your most revenue-generating pages were X, Y and Z</li>
                        <li>17,000 users visited you via campaign XYZ, 10,000 of which resulted in compensation from Adless for premium content access</li>
                    </ul>
                </Card>
            </Section>

            <Section>
                <Text h2>4. We only use necessary cookies</Text>
                <Text>We respect your privacy and don't store or read data from your device for non-essential purposes.</Text>
                <Text>The cookies we store on your device are for one of two purposes: to remain authenticated or to secure the Service (and thus protect you).</Text>
                <Text>Here is an overview of the cookies we use:</Text>
                <Text h3 nativeID='cookies'>Necessary Functional Cookies</Text>
                <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, auto)", gap: 20 }}>
                    <Text><b>Name</b></Text><Text><b>Purpose</b></Text><Text><b>Duration</b></Text>
                    <Text>BearerToken</Text><Text>Used to make you remain signed in.</Text><Text>3 years</Text>
                </div>
                <Text h3>Preferences Cookies</Text>
                <Text>We do not store any any preference cookies by default but instead ask for your consent when you want to store a preference that requires cookies.</Text>
                <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, auto)", gap: 20 }}>
                    <Text><b>Name</b></Text><Text><b>Purpose</b></Text><Text><b>Duration</b></Text>
                    <Text>lng</Text><Text>To store your language preference if you manually change it.</Text><Text>Session</Text>
                </div>
                <Text h3>Cookies on Earner Websites</Text>
                <Text>Sometimes Earners need to store cookies on their domains on our behalf. These are necessary functional cookies only and we do this for Adless customers to remain authenticated so we can deliver the Service.</Text>
                <Text>As an Earner, we recommend that you update your privacy policy to inform your visitors that accessing content with Adless benefits requires a necessary first-party cookie (which it is from your perspective) on some browsers. Since this is a necessary first-party cookie for a user-requested service, cookie consent is not required (ePrivacy Directive Art 5.3 Criterion B). Here is how we describe it:</Text>
                <br /> <br />
                <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, auto)", gap: 20 }}>
                    <Text><b>Name</b></Text><Text><b>Purpose</b></Text><Text><b>Duration</b></Text>
                    <Text>AdlessClientToken</Text><Text>On some browsers, using the "Access with Adless" function to receive your Adless subscription benefit(s) requires a necessary first-party cookie for authentication.</Text><Text>Up to 3 years <br />(depending on browser restrictions)</Text>
                </div>
            </Section>

            <Section>
                <Text h2>More Information</Text>
                <Text>In legal terms, Adless is the Data Controller.</Text>
                <Text>Sometimes we use third-party Data Processors to help deliver the Service. We will always inform you clearly when you provide data that will be shared with a third-party Data Processor. An example of this is when you provide your credit card information to our third-party payment service provider.</Text>
                <Text>You can reach our Data Protection Officer at <EmailLink to="dpo@adless.net" /> if you have any questions, concerns, or for any other reason need to talk to us about privacy and data protection. You can also contact <SupportEmailLink /> if you have more general questions or requests and we will make sure it goes to the right place.</Text>
            </Section>
        </>
    );
};

export const Earner = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()

    return (
        <>
            <Notice>These terms are a part of the <Link to="/terms">Standard Terms</Link>.</Notice>
            <Section>
                <Text h1>Standard Earner Terms</Text>
                <Text>To earn with Adless, you need to accept these Standard Earner Terms.</Text>

                <Text>Effective from August 5th 2022 and until replaced by a newer revision.</Text>

                <Text h2>Definitions & Scope</Text>
                <Text>We collectively refer to Adless ApS, any group company and vendors acting on behalf of Adless ApS, or any group company as "Adless", "we", "us", "our".</Text>

                <Text>We refer to any website, service or product offered by Adless may be referred to as "the Service", "the Product", "services", "products".</Text>

                <Text>We collectively refer to to accessing, signing up for, contracting with, or otherwise interacting with our services as "using".</Text>

                <Text>In case of inconsistencies between the <Link to="/terms">Standard Terms</Link> and the Standard Earner Terms, the Standard Terms take precedence.</Text>

                <Text>We use Earning Channel to refer to any way that you, as a member, can receive any kind of compensation from Adless. By signing up for one or more Earning Channels or receiving compensation from Adless, we refer to you as an Earner or Earner Partner and say that you are offering the service(s) or benefit(s) defined in the Earning Channel(s).</Text>

                <Text>We say that you claim compensation for a service when you in any way claim eligibility to Adless for receiving compensation for delivering said service, including but not limited using our official client code or API.</Text>

                <Text>To be an Earner, you need to accept these Standard Earner Terms as well as any additional terms for the specific Earning Channel(s) you sign up for.</Text>

                <Text>All transitively linked terms and policies as well as specific terms, including but not limited to eligibility criteria, for the Earning Channel(s) you sign up for collectively form the Terms of the Agreement. The Standard Earner Terms are part of the <Link to="/terms">Standard Terms</Link> which you agree to when becoming a member.</Text>

                <Text>We may change any of the Terms by posting revised Terms on our website. Unless you terminate your account, the new Terms will be effective immediately upon posting on the effective date indicated in the new Terms, as applicable, and apply to any continued or new use of the Earning Channel(s). We may change the Earning Channels or any features of Earning Channels at any time, and we may discontinue an Earning Channel or any features of the Earning Channel at any time.</Text>

                <Text>You may terminate your account or stop offering one or more services at any time using the <Link to="/account">Account Panel</Link>.</Text>

                <Text h2>1. General</Text>

                <Text>By being an Earner, you are agreeing to be bound by these Terms and Conditions and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from being an Earner.</Text>

                <Text>When you sign up for an account and agree to these Terms, the Agreement between you and Adless is formed, and the term of the Agreement (the "Term") will begin. The Term will continue for as long as you have an Adless account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first. If you sign up for an account on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.</Text>

                <Text h2>2. Compensation & Invoicing</Text>

                <Text>As an Earner, you are a vendor to Adless and agree to license content to Adless according to the terms of the services you claim compensation for.</Text>

                <Text>You are not allowed to claim compensation for content you do not have the necessary legal rights to license to Adless or distribute, e.g. due to copyright, or content that is illegal to distribute in Denmark, your contry of origin or the visitors contry of origin.</Text>

                <Text>The licensed content is considered delivered, when it has been displayed, according to the terms of the services you intend to claim compensation for, to an eligible member, and Adless has been immediately notified of the delivery with the official client implementation.</Text>

                <Text>You agree that Adless determines, according to our proprietary revenue share algorithm, the size of the compensation, if any. Due to the variable nature of revenue and claims, it is not possible to guarantee a specific compensation value per claim.</Text>

                <Text>We issue a self-billing invoice for bookkeeping. For VAT-registred earners, we use reverse-charge for VAT. By accepting these Terms, you accept that we will perform self-billing according to the herein defined delivery and compensation terms.</Text>

                <Text>You agree that you are responsible for your bookkeeping and tax reporting and that Adless cannot be held responsible, in any way, for losses (including, but not limited to financial losses), that occur as a result of erroneus bookkeeping, erroneous tax reporting or any other failure on our part.</Text>

                <Text>You are responsible for validating any financial documentation you receive from Adless and contact us at <SupportEmailLink /> if you find any issues.</Text>

                <Text h2>3. Integration</Text>
                <Text>You may only integrate with Adless using the official client, hosted at <ExternalLink to="https://static.adless.net/adless.js">static.adless.net/adless.js</ExternalLink>. Any API calls or other integrations with Adless requires a written agreement with Adless. Contact <SupportEmailLink></SupportEmailLink> if needed.</Text>

                <Text>You may not make any attempts to claim compensation for offering service(s) while the Adless member is not consuming your content. This is managed automatically by the official client if you use it.</Text>

                <Text>You may not use any data originating from Adless to profile or track users. You may also not sell or share any such data with other parties.</Text>

                <Text>In some browsers, we store an access token in a necessary cookie names AdlessClientToken on you clients device in order. We collect the users acceptance for placing this cookie and describe it in our <Link to="/privacy">Privacy Policy</Link>. Adless cannot be held responsible for any failure to comply with GDPR or other legislation related to reading and writing of cookies as part of your integration with Adless on your website or on any other distribution channel.</Text>

                <Text>You may never store the access token on your servers or forward it to any other party than Adless.</Text>

                <Text>We sometimes need to store a necessary, first-party cookie on your behalf on your website for visitors to receive Adless benefits. It is your responsibility to inform your visitors appropriately according to law. See our <Link to="/get-started#prereq">Getting Started guide</Link> for details.</Text>

                <Text h2>4. Limitations</Text>

                <Text>Adless does not make any guarantees about minimum compensation, implied or otherwise, and cannot be held financially responsible.</Text>

                <Text>Adless cannot be held responsible for any losses incurred to you under any circumstances, including, but not limited to, the unavailability of the Service.</Text>

                <Text h2>5. Eligibility</Text>

                <Text>You may not provide access to adult content, even if this is unrelated to your Adless integration.</Text>
                <Text>You may not provide access to content that is against the law, even if this is unrelated to your Adless integration.</Text>
                <Text>You may not integrate with Adless for content for which you do not have the appropriate right to do so, including but not limited to rights to distribute the content.</Text>
            </Section>
        </>
    );
};

export default Terms;