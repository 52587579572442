
import React, { useContext } from 'react';
import {
    Text,
} from 'react-native-elements';
import { View, Row, Column, PrimaryColorPage, Section } from '../components/Layout'
import { PricingCard, ThemeContext } from 'react-native-elements';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    return (
        <View>
          <Text h1 style={{textAlign: 'center', paddingTop:'10%'}}>Not Found 🤔</Text>
          <Text style={{textAlign: 'center'}}>We're terribly sorry but this page does not exist.</Text>
          <Text style={{textAlign: 'center'}}>If this is causing you troubles, please let us know at <a href="mailto:support@adless.net">support@adless.net</a>.</Text>
        </View>
      );
};
