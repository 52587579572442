import { Text, View } from 'components/Layout';
import React from 'react';
import { SupportEmailLink } from 'Routing';

export class ErrorBoundary extends React.Component {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
  
    render(): React.ReactNode {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <View>
            <Text h1 style={{textAlign: 'center', paddingTop:'10%'}}>Something's not right 🤔</Text>
            <Text style={{textAlign: 'center'}}>We're terribly sorry. Maybe refreshing the page will work?</Text>
            <Text style={{textAlign: 'center'}}>If this is causing you troubles, please let us know at <SupportEmailLink></SupportEmailLink>.</Text>
          </View>
        );
      }
  
      return this.props.children; 
    }
  }