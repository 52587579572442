
import { CurrentResponse, InvoicesResponse, PaymentAuthorizeRequest, PaymentAuthorizeResponse, PayoutCurrentResponse, PayoutStoreRequest, PayoutStoreResponse, RevolutInitRequest, RevolutInitResponse, RevolutStoreRequest, RevolutStoreResponse, StoreRequest, StoreResponse } from 'api/gen/payment';
import { adlessCommand, adlessDelete, adlessPut, adlessQuery } from '.';
import { CampaignCreateRequest, CampaignCreateResponse, CampaignDeleteRequest, CampaignDeleteResponse, CampaignListResponse, CampaignReadRequest, CampaignReadResponse, CampaignUpdateRequest, CampaignUpdateResponse } from './gen/campaign';

export default {
    create: (req: CampaignCreateRequest) => adlessCommand<CampaignCreateResponse>("/v1/campaign", req),
    update: (req: CampaignUpdateRequest) => adlessPut<CampaignUpdateResponse>(`/v1/campaign/${req.id}`, req),
    delete: (req: CampaignDeleteRequest) => adlessDelete<CampaignDeleteResponse>(`/v1/campaign/${req.id}`, req),
    read: (req: CampaignReadRequest) => adlessQuery<CampaignReadResponse>(`/v1/campaign/${req.id}`, req),
    list: () => adlessQuery<CampaignListResponse>("/v1/campaign"),
}