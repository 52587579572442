import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'react-native-elements';

import { Column, Divider, Row, Text } from 'components/Layout';
import { useConfirmModal } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { Link } from 'Routing';
import { Picker } from '@react-native-picker/picker';

export const Footer = () => {
    const { t } = useTranslation()
    const { theme } = useContext(ThemeContext)

    const { i18n } = useTranslation()

    const [lng, setLng] = useState(i18n.language.substring(0, 2))

    const [changeLanguageModal, showConfirmChangeLanguageModal] = useConfirmModal(<>
        <Text>By changing the website language, you must agree that we place a preference cookie on your device to remember your choice. Do you accept this?</Text>
        <br /><br />
        <Text>See our <Link to={"/privacy#cookies"}>Privacy Policy</Link> for more information about how we use cookies.</Text>
    </>, {
        onConfirm: () => {
            if(lng){
                document.cookie = "lng=" + lng
                document.location.reload()
            }
        },
        onClose: () => setLng(i18n.language.substring(0, 2))
    })

    useEffect(() => {
        if(lng != i18n.language.substring(0, 2)){
            showConfirmChangeLanguageModal()    
        }
    }, [lng])

    const style = { color: theme.colors?.grey3, fontSize: 13, marginBottom: 0 }
    const rightAlignStyle = { ...style, textAlign: 'right' }

    return (
        <>
        {changeLanguageModal}
        <footer>
            <Row style={{
                ...theme.Header,
                paddingTop: "20px",
                paddingBottom: "20px",
            }}>
                    <Row style={{ justifyContent: 'center', flexWrap: "wrap", gap: 20 }}>
                         <Column gap={'1em'} style={{flexGrow: 0,}}>
                            <Link to="/terms" style={rightAlignStyle}>{t('footer.tos')}</Link>
                            <Link to="/privacy" style={rightAlignStyle}>{t('footer.privacy')}</Link>
                            {/* <Link to="/culture" style={rightAlignStyle}>Culture & Career</Link> */}
                            <Link to="/about" style={rightAlignStyle}>{t('footer.about')}</Link>
                            <Picker onValueChange={setLng} selectedValue={lng} style={{...theme.Header, textAlign: "right", border: "none"}}>
                                <Picker.Item value="en" label='English'></Picker.Item>
                                <Picker.Item value="da" label='Dansk'></Picker.Item>
                            </Picker>
                        </Column>
                        <Divider orientation="vertical" width={1} color={theme.colors?.grey2} />
                        <Column style={{flexGrow: 0}}>
                            <Text style={style}>© 2022 Adless Aps</Text>
                            <Text style={style}>VAT DK43129287</Text>
                            <Text style={style}><a style={style} href="mailto:hi@adless.net">hi@adless.net</a></Text>
                        </Column>
                    </Row>
            </Row>
        </footer>
        </>
    );
};
