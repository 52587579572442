

import api, { useIsLoading } from 'api';
import { ListItem, Notice, Section, Success, Text, View, Warning, Button, Row, Card, Column } from 'components/Layout';
import { Loading } from 'components/Loading';
import { Done, Spinner, Warning as Warn } from 'components/Symbols';
import useNavigateTo from 'hooks/useNavigateTo';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { useTranslation } from 'react-i18next';
import { Icon } from 'react-native-elements';
import ReactPlayer from 'react-player';
import { DownloadLink, ExternalLink, Link, SupportEmailLink, useSearchParams } from 'Routing';

const Preconditions = ({ isExpanded, expand }) => {
    const me = api.account.me()
    const services = api.earner.list()

    const hasEnrollments = (services?.data?.enrollments || []).length > 0
    const isAccountLoading = useIsLoading(me)
    const isServicesLoading = useIsLoading(services)

    return (
        <ListItem.Accordion nativeID="prereq" isExpanded={isExpanded} onPress={expand} content={
            <>
                <ListItem.Content>
                    <ListItem.Title><Text h2>{!isServicesLoading && !hasEnrollments ? <Warn /> : null}Prerequsites</Text></ListItem.Title>
                </ListItem.Content>
            </>
        }>
            <div style={{ height: !isExpanded ? 0 : 'inherit', overflowY: 'hidden' }}>
                <Text>Before you start earning with Adless following this guide, you need to:</Text>
                <ListItem>
                    <ListItem.Chevron Component={isAccountLoading ? () => <Spinner /> : me?.data?.account ? () => <Done /> : () => <Warn />}></ListItem.Chevron>
                    <ListItem.Content><Text><Link to="/settings">Create an Adless account and make sure your account information is accurate</Link>.</Text></ListItem.Content>
                </ListItem>
                <ListItem>
                    <ListItem.Chevron Component={isServicesLoading ? () => <Spinner /> : hasEnrollments ? () => <Done /> : () => <Warn />}></ListItem.Chevron>
                    <ListItem.Content><Text><Link to="/earnings">Sign up for the services you want to offer and accept the terms</Link>.</Text></ListItem.Content>
                </ListItem>
                <ListItem>
                    <ListItem.Content><Text>You should also update your privacy policy to inform your visitors that accessing content with Adless benefits requires a necessary first-party cookie on some browsers. We already inform Adless members that we might place this cookie on third-party sides when they accept our Privacy Policy, but to be on the safe side we recommend that you also inform your visitor of this. Since this is a necessary first-party cookie for a user-requested service, cookie consent is not required (ePrivacy Directive Art 5.3 Criterion B). Here is how we describe it:</Text>
                        <br /> <br />
                        <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, auto)", gap: 20 }}>
                            <Text><b>Name</b></Text><Text><b>Purpose</b></Text><Text><b>Duration</b></Text>
                            <Text>AdlessClientToken</Text><Text>On some browsers, using the "Access with Adless" function to receive your Adless subscription benefit(s) requires a necessary first-party cookie.</Text><Text>Up to 3 years <br />(depending on browser restrictions)</Text>
                        </div>

                    </ListItem.Content>
                </ListItem>
            </div>
        </ListItem.Accordion>);
}

export default Preconditions