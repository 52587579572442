
import React from 'react';
import { Signup, Confirm } from '../components/Newsletter';
import { useTranslation } from 'react-i18next';
import { Link } from '../Routing';
import { PrimaryColorPage, Row, Column, Card, Text } from '../components/Layout';

export const Newsletter = () => {
    const { t } = useTranslation()


    return (
        <PrimaryColorPage>
            <Column>
                <Row style={{
                    flexGrow: 0,
                    justifyContent: 'center',
                    flexShrink: 1,
                }}>
                    <Card>
                        <Card.Title h1>{t('signup.title')}</Card.Title>
                        <Text style={{ textAlign: "center" }} h2>🎉 We are thrilled about your interest! 🎉</Text>
                        <p>Unfortunately we are not quite ready for you yet... but please do sign up for our newsletter and we'll let you know as soon as we are ready for business!</p>
                        <Signup />
                    </Card>
                </Row>
                </Column>
        </PrimaryColorPage>
    );
};

export const NewsletterConfirm = () => {
    const { t } = useTranslation()

    return (
        <PrimaryColorPage>
            <Column>
                <Row style={{
                    flexGrow: 0,
                    justifyContent: 'center',
                    flexShrink: 1,
                }}>
                    <Card>
                        <Card.Title h1>Confirm newsletter registration</Card.Title>
                        <Confirm />
                    </Card>
                </Row>
                </Column>
        </PrimaryColorPage>
    );
};

export default Newsletter;