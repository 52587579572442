import React, { useEffect, useMemo, useState } from 'react';

import FormData from 'form-data'

import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export var ClientPromiseResolve: (_: AxiosInstance) => void;
export var ClientPromise = new Promise<AxiosInstance>((resolve) => {
    ClientPromiseResolve = resolve
})

// Quirk otherwise protobuf will not know how to serialize a BigInt
BigInt.prototype.toJSON = function() {
    return parseInt(this)
  }

export const updateClientPromise = (client: AxiosInstance) => {
    ClientPromise = new Promise<AxiosInstance>((resolve, reject) => {
        ClientPromiseResolve(client)
        resolve(client)
    })
    console.log("Client ready")
}

export const get = <REST>(url: string) => {
    const [refetch, setRefetch] = useState(new Date())

    const [res, setRes] = useState<{
        data?: REST,
        isLoading: boolean,
        error?: any,
        refetch?: () => void
    }>({
        isLoading: true,
    })

    useEffect(() => {
        ClientPromise.then(client => client.get<REST>(url)).then((res) => setRes({
            data: res.data,
            error: res.status > 299 ? res.data : undefined,
            isLoading: false,
            refetch: () => setRefetch(new Date())
        })).catch((err) => setRes({
            isLoading: false,
            error: err,
            refetch: () => setRefetch(new Date())
        }))
    }, [refetch, setRefetch, setRes])
    
    return res
}

export async function hooklessGet<REST>(url: string) {
    const client = await ClientPromise;
    return await client.get<REST>(url, {
        withCredentials: true,
    })
}

export async function post<REQT, REST>(url: string, body: REQT, config?: AxiosRequestConfig<REST>) {
    const client = await ClientPromise;
    return await client?.post<REST>(url, body, {
        withCredentials: true,
        ...config,
    }).then(res => ({
        isLoading: false,
        error: res.status > 299 ? res.data : undefined,
        ...res,
    }), err => ({isLoading: false, error: err}))
}

export async function del<REST>(url: string, config?: AxiosRequestConfig<REST>) {
    const client = await ClientPromise;
    return await client.delete<REST>(url, {
        withCredentials: true,
        ...config,
    }).then(res => ({
            isLoading: false,
            error: res.status > 299 ? res.data : undefined,
            ...res,
        }), err => ({isLoading: false, error: err}))
}

export type UploadableFile = {
    uri: string,
    name: string,
    type: string,
}

export async function upload<REST>(url: string, file: UploadableFile) {
    const client = await ClientPromise;

    const data = new FormData()
    data.append("file", file)

    return await client?.post<REST>(url, data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            // if backend supports u can use gzip request encoding
            // "Content-Encoding": "gzip",
        },
        transformRequest: (data, headers) => {
            // !!! override data to return formData
            // since axios converts that to string
            return data;
        },
        onUploadProgress: (progressEvent) => {
            // use upload data, since it's an upload progress
            // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
        },
        data: data,
        withCredentials: true,
    })
}

export type loadable = {
    isLoading: boolean
} | Promise<any> | undefined
export function useIsLoading(...calls: loadable[]) {
    const [isLoading, setIsLoading] = useState(true);

    useMemo(() => {
        Promise.all(calls).finally(() => {
            setIsLoading(calls.some(c => c === undefined || c.isLoading ))
        })
    }, calls);

    return isLoading;
}
