import React, { useContext } from 'react';
import { View, ActivityIndicator } from 'react-native';

import { Header as RNHeader, ThemeContext } from 'react-native-elements';

export const Loading = () => {
    const { theme } = useContext(ThemeContext)

    return (
        <View style={{
            paddingTop: 50,
            alignItems: "center"
        }}>
            <ActivityIndicator size="large" color={theme.colors?.primary} />
        </View>
    );
};
