import { AppRegistry } from 'react-native';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import materialCommunityIconsFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialIconsFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import App from './App';
import { name as appName } from './app.json';

// Generate required css
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: FontAwesome;
}

@font-face {
    src: url(${materialIconsFont});
    font-family: MaterialIcons;
  } 

@font-face {
  src: url(${materialCommunityIconsFont});
  font-family: MaterialCommunityIcons;
} 
  `;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

document.head.appendChild(style);

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
