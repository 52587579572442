import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../public/locales/en/translation.json';
import translationDA from '../public/locales/da/translation.json';

export const resources = {
    en: {
        translation: translationEN
    },
    da: {
        translation: translationDA,
    }
} as const;

i18n.languages = ['en', 'da']

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
    debug: false,
    resources: resources,
    detection: {
        caches: [],
        lookupCookie: "lng"
    }
});

export default i18n;