import { Row, StaticImage } from 'components/Layout';
import useNavigateTo from 'hooks/useNavigateTo';
import React, { useContext, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Header as RNHeader, Icon, ThemeContext } from 'react-native-elements';
import { ExternalLink, Link } from 'Routing';

const MOBILE_CUTOFF_WIDTH = 700

export const Header = () => {
    const { theme } = useContext(ThemeContext)
    const d = useWindowDimensions()

    const isHome = window.location.pathname.substring(0, 1) === "/"

    return (
        <RNHeader
        containerStyle={{height: 70}}
            style={{backgroundColor: isHome ? "#fff" : theme.colors?.primary}}
            rightContainerStyle={{
                flexGrow: 3,
                flexBasis: d.width > MOBILE_CUTOFF_WIDTH ? 300 : 0
            }}
            leftContainerStyle={{
                flexBasis: d.width > MOBILE_CUTOFF_WIDTH ? 300 : 0,
                flexGrow: 3,
            }}
            centerComponent={<CenterComponent isHome={isHome} />}
            rightComponent={<RightComponent isHome={isHome} />}
            centerContainerStyle={{ paddingTop: '10px' }}
        />
    );
};

const CenterComponent = ({isHome}) => {
    const { theme } = useContext(ThemeContext)

    return <Row style={{ alignItems: 'center' }}>
        <StaticImage source={{ uri: '/img/logo-white.svg' }} onPress={useNavigateTo('/')} style={{ height: 50, width: 100 }} resizeMode="contain" transition={false} PlaceholderContent={<div style={{ backgroundColor: theme.colors?.primary, width: 100, height: 50 }}></div>}></StaticImage>
    </Row>
}

const RightComponent = ({isHome}) => {
    const { theme } = useContext(ThemeContext)
    const [showMenu, setShowMenu] = useState(false)

    const d = useWindowDimensions()

    return <Row style={{ alignItems: 'center', gap: 20, justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
        {d.width > MOBILE_CUTOFF_WIDTH ? (<>
            {/* <a style={{ color: theme.colors?.secondary, display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', cursor: 'pointer', overflowY: 'hidden' }} onClick={() => setShowMenu(!showMenu)}>PRODUCTS <Icon type='font-awesome' name='chevron-down' iconStyle={{ transform: 'rotate(' + (showMenu ? 180 : 0) + 'deg)', transition: 'transform 250ms ease' }} size={10} color={theme.colors?.secondary}></Icon>
            <div style={{ backgroundColor: theme.colors?.primary, color: theme.colors?.secondary, height: showMenu ? 500 : 0, overflowY: 'hidden', width: 250, position: 'absolute', right: -10, top: 67 }}>
                <View>
                    <Text h4>For Content Creators</Text>
                    <Link to="/products/subscriptions">Subscription Networks</Link>
                </View>
            </div>
            </a> */}
            <Link to="/products" style={{ textDecoration: 'none' }}>PRODUCTS</Link>
            <ExternalLink to="https://blog.adless.net" style={{ textDecoration: 'none' }}>BLOG</ExternalLink>
        </>) : null}
        <Icon color={theme.colors?.secondary} name='account-circle' onPress={useNavigateTo('/account')} style={{ padding: 10 }}></Icon>
    </Row>
}
