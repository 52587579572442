
import { CurrentResponse, InvoicesResponse, PaymentAuthorizeRequest, PaymentAuthorizeResponse, PayoutCurrentResponse, PayoutStoreRequest, PayoutStoreResponse, RevolutInitRequest, RevolutInitResponse, RevolutStoreRequest, RevolutStoreResponse, StoreRequest, StoreResponse } from 'api/gen/payment';
import { adlessCommand, adlessQuery } from '.';

export default {
 balance: () => adlessQuery<any>("/v1/payment/balance"),
 session: () => adlessQuery<any>("/v1/payment/session"),
 authorize: (req: PaymentAuthorizeRequest) => adlessCommand<PaymentAuthorizeResponse>("/v1/payment/authorize", req),
 store: (req: StoreRequest) => adlessCommand<StoreResponse>("/v1/payment/store", req),
 current: () => adlessQuery<CurrentResponse>("/v1/payment/current"),
 invoices: () => adlessQuery<InvoicesResponse>("/v1/payment/invoices"),
 revolut: {
    init: (req: RevolutInitRequest) => adlessCommand<RevolutInitResponse>("/v1/payment/revolut/init", req),
    store: (req: RevolutStoreRequest) => adlessCommand<RevolutStoreResponse>("/v1/payment/revolut/store", req),
 },
 payout: {
   current: () => adlessQuery<PayoutCurrentResponse>("/v1/payment/payout/current"),
   store: (req: PayoutStoreRequest) => adlessCommand<PayoutStoreResponse>("/v1/payment/payout/store", req),
 },
}