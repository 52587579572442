import { useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { QueryObserverResult } from 'react-query/core';
import account from './Account';
import admin from './admin';
import analytics from './analytics';
import authentication from './authentication';
import campaign from './campaign';
import earner from './Earner';
import { ErrorCause } from './gen/common/c_errors';
import influencer from './influencer';
import payment from './Payment';
import { newsletter_signup, newsletter_verify } from './Public';
import subscription from './Subscription';
import Transactions from './Transactions';

const baseOptions = {
    credentials: 'include',
}

const queryOptions = {
    staleTime: Infinity
}

const commandOptions = {
    ...baseOptions,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
}

export type Error = {
    code: number,
    message: string,
    cause: ErrorCause,
} | null

export type commandResponse<T> = {
    data: T
    error: Error | null
    isLoading: boolean
    status: number
}

export function adlessQuery<T>(endpoint: string, options = {}): QueryObserverResult<T, Error> {
    return genericQuery<T>(API_URL + endpoint, options)
}

export function genericQuery<T>(url: string, options = {}): QueryObserverResult<T, Error> {
    return useQuery<unknown, unknown, T>(url, () => fetch(url, {
        ...baseOptions,
        ...options
    }).then(unauthorizedHandler).then<T>((res) => res.json()), queryOptions);
}

function unauthorizedHandler(res) {
    if ((res?.status == 401 || res?.status == 403) && window.location.pathname.substring(0, 12) != "/get-started" && window.location.pathname.substring(0, 1) != "/") {
        window.location.href = AUTH_URL + "/login?redirect=" + encodeURIComponent(window.location.pathname+window.location.search)
    }

    return res
}

export async function adlessCommand<T>(endpoint: string, body: Object, options = {}): Promise<commandResponse<T>> {
    return genericCommand<T>(API_URL + endpoint, body, options)
}

export async function adlessPut<T>(endpoint: string, body: Object, options = {}): Promise<commandResponse<T>> {
    return genericCommand<T>(API_URL + endpoint, body, {
        ...options,
        method: 'PUT',
    })
}

export async function adlessDelete<T>(endpoint: string, body: Object, options = {}): Promise<commandResponse<T>> {
    return genericCommand<T>(API_URL + endpoint, body, {
        ...options,
        method: 'DELETE',
    })
}

export async function genericCommand<T>(url: string, body: Object, options = {}): Promise<commandResponse<T>> {
    const resp = await fetch(url, {
        ...commandOptions,
        ...options,
        body: JSON.stringify(body),
    });

    let err = null
    if (!resp.ok) {
        err = await resp.json()
        if (err.details.length == 1) {
            err.cause = ErrorCause[err.details[0].cause]
        }
    }

    let data = resp.ok ? await resp.json() : ""

    return new Promise(resolve => resolve({
        data: data,
        error: err,
        status: resp.status,
        isLoading: false,
    })).catch((err) => {
        alert(t(err.cause) ?? err.message)
    })
}

const api = {
    account: account,
    authentication: authentication,
    analytics: analytics,
    payment: payment,
    earner: earner,
    subscription:subscription,
    transaction: Transactions,
    influencer: influencer,
    campaign: campaign,
    public: {
        newsletter: {
            signup: newsletter_signup,
            verify: newsletter_verify
        }
    },
    command: genericCommand,
    query: genericQuery,
    admin: admin,
}

export default api;

export type loadable = {
    isLoading: boolean
} | Promise<any> | undefined

type typedLoadable<T> = UseQueryResult<T, Error> | Promise<T> | undefined


type errorAndLoadable = {
    isLoading: boolean
    error?: any
} | Promise<any>

export const NotReady = {
    isLoading: true
}

export function useIsLoading(...calls: loadable[]) {
    const [isLoading, setIsLoading] = useState(true);

    useMemo(() => {
        Promise.all(calls).finally(() => {
            setIsLoading(calls.some(c => c && c.isLoading ))
        })
    }, calls);

    return isLoading;
}

export function awaitCommand<T>(call: Promise<T>) {
    const [res, setRes] = useState<T>()

    useEffect(() => {
        call.then<T>(setRes)
    }, [setRes])

    return res;
}
