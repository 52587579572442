import React from 'react'
import {Helmet} from 'react-helmet-async'
import { useLocation } from 'react-router-dom';


const SEO = () => {
    const location = useLocation()
        return <Helmet>
            <link rel="canonical" href={"https://adless.net" + location.pathname} />
        </Helmet>
}

export default SEO