import { StyleSheet } from 'react-native';
import { Theme } from 'react-native-elements';
import { createGlobalStyle } from 'styled-components';

export const Styling = StyleSheet.create({
  Base: {
    backgroundColor: '#000',
    color: '#FFF',
    display: 'flex',
  }
});

export const DefaultTheme: Theme = {
  Header: {
    backgroundColor: "#123",
    color: "#fff",
    leftComponent: {
      color: "#fff",
      style: {
        color: "#fff"
      }
    },
    rightComponent: {
      color: "#fff",
      style: {
        color: "#fff"
      }
    },
    centerComponent: {
      style: {
        color: "#fff",
        fontSize: 20,
      }
    },
    containerStyle: {
      color: "#fff",
      borderBottomWidth: 0,
      height: 75,
    },
  },

  Text: {
    style: {
      width: "100%",
      justifyContent: "inherit",
      fontSize: 15,
      fontFamily: 'inherit',
      marginBottom: 5,
      lineHeight: '2em',
      fontWeight: 100,
      
    },
    h1Style: {
      fontSize: 42,
      fontWeight: "normal",
      marginTop: 15,
      letterSpacing: '0.2ch'
    },
    h2Style: {
      marginTop: 10,
      fontSize: 25,
      letterSpacing: '0.2ch'
    },
    h3Style: {
      marginTop: 10,
      fontSize: 20,
      letterSpacing: '0.2ch'
    },
    h4Style: {
      marginTop: 10,
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.2ch'
    }
  },

  Button: {
    buttonStyle: {
      marginTop: 20,
    },
    titleStyle: {
      fontFamily: 'inherit'
    }
  },

  Avatar: {
    containerStyle: {
      backgroundColor: "#123"
    },
  },

  Card: {
    containerStyle: {
    },
    titleStyle: {
    },
  },

  colors: {
    primary: "#123",
    secondary: "#fff"
  },

  Input: {
    style: {
      fontFamily: 'inherit'
    },
    inputContainerStyle: {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: "rgb(134, 147, 158)",
      padding: 10,
      justifyContent: "inherit",
      fontSize: 'inherit',
      marginBottom: 5,
      lineHeight: '1.5em',
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      borderRadius: 0,
      background: "none",
      color: "inherit",
    }
  }
};

export const GlobalStyles = createGlobalStyle`
  * {
    font-family: sans-serif;
    letter-spacing: 0.1ch;
    text-underline-offset: 0.5ch;
  }`