import api from 'api';
import { View } from 'components/Layout';
import { Loading } from 'components/Loading';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input as RNInput, Text, ThemeContext } from 'react-native-elements';
import { useSearchParams } from 'Routing';

type props = {
    error: string | undefined;
    onSignup: (email: string) => void;
}

export const Newsletter = ({ error, onSignup }: props) => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()

    const [state, setState] = useState<string>("")
    const [captchaSolved, setCaptchaSolved] = useState<boolean>(false)

    const submit = useCallback(() => {
        const err = onSignup(state)
    }, [state])

    return (
        <View style={{
            alignContent: "center",
            justifyContent: "center",
        }}>
            {error && (
                <Text testID="error" style={{ textAlign: "center", fontSize: 20, padding: 10, color: theme.colors?.error }}>{error}</Text>
            )}

            <RNInput
                autoCapitalize="none"
                autoCorrect={false}
                autoCompleteType='email'
                importantForAutofill="yes"
                placeholder='E-mail'
                leftIcon={{ type: 'font-awesome', name: 'envelope' }}
                onChangeText={setState}
            />

            <Button onPress={submit} title="Subscribe to Newsletter!" />
        </View>
    );
};


export const Signup = () => {
    const [hasSignedUp, setHasSignedUp] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const onSignup = async function (email: string) {
        const { error: err } = await api.public.newsletter.signup({
            email: email,
        })

        if (!err) {
            setHasSignedUp(true)
        }

        setError(err?.message)
    }

    return hasSignedUp ? (
        <Text>Thanks for subscribing to our newsletter! We sent you an e-mail with a confirmation link to confirm your subscription.</Text >
    ) : (
        <Newsletter error={error} onSignup={onSignup} />
    );
};

export const Confirm = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        async function load() {
            const { error: err } = await api.public.newsletter.verify({
                email: searchParams.get("email"),
                challenge: searchParams.get("challenge"),
            })

            setError(err?.message)
            setIsLoading(false)
        }
    
        load()
      }, [])

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return <Text>{error}</Text>
    }

    return <Text>Thank you for signing up to our newsletter, we'll be in touch.</Text>
};
