import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { PrimaryColorPage } from 'components/Layout';
import { ModalContainer } from 'components/Modal';
import { ErrorBoundary } from 'ErrorBoundary';
import 'i18n';
import React, { useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import {
  Platform, StatusBar,
  View
} from 'react-native';
import { ThemeProvider } from 'react-native-elements';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { history } from 'Routing';
import { DefaultTheme, GlobalStyles } from 'Styling';
import GTM from 'views/GetStarted/GTM';
import Wordpress from 'views/GetStarted/Wordpress';
import { NewsletterConfirm } from './views/Newsletter';
import { NotFound } from './views/NotFound';
import { Earner as EarnerTerms, Privacy } from './views/terms/Terms';
import { HelmetProvider } from 'react-helmet-async'
import SEO from 'components/SEO';

import { ClientPromiseResolve, updateClientPromise } from 'apiv2';
import axios from 'axios';

// Views
const Home = React.lazy(() => import('views/NewHome'));
const AdLanding = React.lazy(() => import('views/AdLanding'));
const Account = React.lazy(() => import('views/Account'));
const Earnings = React.lazy(() => import('views/earnings/overview'));
const EarningsDetails = React.lazy(() => import('views/earnings/details'))
const Newsletter = React.lazy(() => import('views/Newsletter'));
const Settings = React.lazy(() => import('views/Settings'));
const Subscriptions = React.lazy(() => import('views/Subscriptions'));
const SubscriptionDetails = React.lazy(() => import('views/Subscriptions/Details'))
const Culture = React.lazy(() => import('views/Culture'));
const Activate = React.lazy(() => import('views/activate'));
const Login = React.lazy(() => import('views/Login'));
const Signup = React.lazy(() => import('views/Signup'));
const Authorize = React.lazy(() => import('views/Authorize'));
const Why = React.lazy(() => import('views/Why'));
const SubscriptionsBrowse = React.lazy(() => import('views/Subscriptions/Browse'));
const Transactions = React.lazy(() => import('views/Transactions'));
const Invoices = React.lazy(() => import('views/Invoices'));
const FAQ = React.lazy(() => import('views/FAQ'));
const Terms = React.lazy(() => import('views/terms/Terms'));
const TermsInfluencer = React.lazy(() => import('views/terms/influencer'));
const About = React.lazy(() => import('views/About'));
const Advertise = React.lazy(() => import('views/Advertise'));

const Products = React.lazy(() => import('views/Products'));
const ProductAnalytics = React.lazy(() => import('views/products/analytics'));
const ProductPlatform = React.lazy(() => import('views/products/platform'));
const ProductInfluence = React.lazy(() => import('views/products/influence'));
const CreateMarketingCampaign = React.lazy(() => import('views/Marketing/create'));
const MarketingCampaigns = React.lazy(() => import('views/Marketing/overview'));
const MarketingCampaignDetails = React.lazy(() => import('views/Marketing/details'));
const MarketingCampaignEdit = React.lazy(() => import('views/Marketing/edit'));

const GetStarted = React.lazy(() => import('views/GetStarted/General'));
const GetStartedNoAds = React.lazy(() => import('views/GetStarted/no-ads'));
const GetStartedGTM = React.lazy(() => import('views/GetStarted/GTM'));
const GetStartedWordpress = React.lazy(() => import('views/GetStarted/Wordpress'));

const LandingPlatform = React.lazy(() => import('views/product-landings/platform'));
const LandingMarketing = React.lazy(() => import('views/product-landings/marketing'));
const LandingInfluencer = React.lazy(() => import('views/product-landings/influence'));
const LandingAnalytics = React.lazy(() => import('views/product-landings/analytics'));
const LandingSubscriptions = React.lazy(() => import('views/product-landings/subscriptions'));
const LandingActivityPub = React.lazy(() => import('views/product-landings/activitypub'));

let Router: any
let Route: any
let Routes: any
if (Platform.OS === 'web') {
  let r = require('react-router-dom')
  Router = r.BrowserRouter
  Route = r.Route
  Routes = r.Routes
} else {
  let r = require('react-router-native')
  Router = r.NativeRouter
  Route = r.Route
  Routes = r.Switch
}

const queryClient = new QueryClient()

Object.defineProperty(String.prototype, 'UCFirst', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

Object.defineProperty(String.prototype, 'TitleCase', {
  value: function() {
    var sentence = this.toLowerCase().split(" ");
    for(var i = 0; i< sentence.length; i++){
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ")
  },
  enumerable: false
});


const App = () => {
  useClient()

  useEffect(() => {
    const scrollToHashElement = (hash: string) => {
      setTimeout(() => {
        const elementToScroll = document.getElementById(hash?.replace("#", ""));

        if (!elementToScroll) return;

        window.scrollTo({
          left: 0,
          top: elementToScroll.offsetTop,
          behavior: "smooth"
        });
      }, 100);
    };

    scrollToHashElement(window.location.hash);
   
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        let ret = target.apply(thisArg, argArray);

        let hash = ""
        if(argArray.length >= 3) {
          const split = argArray[2].split("#")
          if(split.length == 2) {
            hash = split[1]
          }
        }
        scrollToHashElement(hash);

        return ret
      },
    });
  }, [window]);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <SafeAreaProvider>
        <GlobalStyles />
        <HelmetProvider>
            <Router history={history}>
              <SEO />
              <QueryClientProvider client={queryClient}>
                <View style={{display:"flex", minHeight:"100vh"}}>
                    <Header></Header>
                    <StatusBar />
                    <View style={{flexGrow:1}}>
                      <ErrorBoundary>
                        <ModalContainer></ModalContainer>
                        <Routes>
                          { lazyRoute("/", Home) }

                          { lazyRoute("/ad", AdLanding) }

                          { lazyRoute("/products", Products) }
                          { lazyRoute("/products/subscription-networks", LandingSubscriptions) }
                          { lazyRoute("/products/marketing", LandingMarketing) }
                          { lazyRoute("/influencer", LandingInfluencer) }
                          { lazyRoute("/products/influencer", LandingInfluencer) }
                          { lazyRoute("/products/platform", LandingPlatform) }
                          { lazyRoute("/products/activitypub", LandingActivityPub) }
                          { lazyRoute("/products/analytics", LandingAnalytics) }

                          { lazyRoute("/earn", Earnings) }
                          { lazyRoute("/earnings", Earnings) }
                          { lazyRoute("/monetize/content", Earnings) }
                          { lazyRoute("/monetize/influence", ProductInfluence) }
                          { lazyRoute("/monetize/platform", ProductPlatform) }
                          { lazyRoute("/grow/analytics", ProductAnalytics) }
                          { lazyRoute("/grow/marketing/campaign/create", CreateMarketingCampaign) }
                          { lazyRoute("/grow/marketing/campaign/:id", MarketingCampaignDetails) }
                          { lazyRoute("/grow/marketing/campaign/:id/edit", MarketingCampaignEdit) }
                          { lazyRoute("/grow/marketing", MarketingCampaigns) }

                          { lazyRoute("/earnings/:id", EarningsDetails) }
                          { lazyRoute("/newsletter", Newsletter) }
                          <Route exact path="/newsletter/confirm" element={<NewsletterConfirm />}/>
                          { lazyRoute("/account", Account) }
                          { lazyRoute("/settings", Settings) }
                          { lazyRoute("/activate", Activate) }
                          { lazyRoute("/login", Login) }
                          { lazyRoute("/signup", Signup) }
                          { lazyRoute("/authorize", Authorize) }
                          <Route exact path="/get-started/gtm" element={<GTM />}/>
                          <Route exact path="/get-started/wordpress" element={<Wordpress />}/>
                          { lazyRoute("/get-started", GetStarted) }
                          { lazyRoute("/get-started/no-ads", GetStartedNoAds) }
                          { lazyRoute("/why", Why) }
                          { lazyRoute("/subscriptions", Subscriptions) }
                          { lazyRoute("/subscriptions/browse", SubscriptionsBrowse) }
                          { lazyRoute("//subscriptions/:id", SubscriptionDetails) }
                          { lazyRoute("/account/transactions", Transactions) }
                          { lazyRoute("/account/invoices", Invoices) }
                          { lazyRoute("/faq", FAQ) }
                          { lazyRoute("/terms", Terms) }
                          <Route exact path="/terms/earner" element={<EarnerTerms />} />
                          <Route exact path="/privacy" element={<Privacy />} />
                          { lazyRoute("/terms/influencer", TermsInfluencer) }
                          { lazyRoute("/about", About) }
                          { lazyRoute("/culture", Culture) }
                          { lazyRoute("/career", Culture) }
                          { lazyRoute("/advertise", Advertise) }
                          <Route path="*" element={<NotFound />}></Route>
                        </Routes>
                      </ErrorBoundary>
                    </View>
                    <Footer></Footer>
                  </View>
                  <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Router>
            </HelmetProvider>
      </SafeAreaProvider>
      </SkeletonTheme>
    </ThemeProvider>
  );
};

function lazyRoute(path: string, C: React.LazyExoticComponent<() => JSX.Element>): any {
  return <Route exact path={path} element={<><React.Suspense fallback={<PrimaryColorPage></PrimaryColorPage>}><C /></React.Suspense></>} />
}

export default App;

const useClient = () => {
  useEffect(() => {
        const client = axios.create({
            baseURL: "https://api.adless.net",
        })

        updateClientPromise(client)
  }, [ClientPromiseResolve, updateClientPromise])

}