
import Demo from 'components/Demo';
import { ListItem, Notice, Section, Success, Text, View, Warning, Button, Row, Card, Column } from 'components/Layout';
import { Loading } from 'components/Loading';
import { Done, Spinner, Warning as Warn } from 'components/Symbols';
import useNavigateTo from 'hooks/useNavigateTo';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { useTranslation } from 'react-i18next';
import { Icon } from 'react-native-elements';
import ReactPlayer from 'react-player';
import { DownloadLink, ExternalLink, Link, SupportEmailLink, useSearchParams } from 'Routing';
import Preconditions from './shared';

export const GTM = () => {

    const { t } = useTranslation()

    const [step, setStep] = useState<"prereq" | "no_ads" | "paywall">("no_ads")

    return (
        <View>
            <Section>
                <Text h1>Google Tag Manager</Text>
                <Text>Adless makes it easy to get started without any coding using Google Tag Manager (GTM). Adless integration templates are available in the <ExternalLink to="https://tagmanager.google.com/gallery/#/?filter=adless">Tag Manager Community Template Gallery</ExternalLink>.</Text>
                <Text>If you have any problems, we will be happy to help you with your integration if you contact <SupportEmailLink></SupportEmailLink>. Please also consider consulting our general documentation in the <Link to="/get-started">Get Started Guide</Link>.</Text>

                <Notice>
                    <Text h3>There are currently some issues with submitting templates to the Google Tag Manager Community Gallery.</Text>
                    <br /><br />
                    <Text>In the meantime, you can import our GTM Tag templates manually by downloading the template.tpl file:</Text>
                    <br /> <br />
                    <Text><DownloadLink href="https://raw.githubusercontent.com/adless-tech/google-tag-manager-no-ads/main/template.tpl" download>No Ads template</DownloadLink><br />
                        <DownloadLink href="https://raw.githubusercontent.com/adless-tech/google-tag-manager-paywall/main/template.tpl" download>Paywall template</DownloadLink></Text>
                    <br /> <br />
                    <Text>You use the template in Google Tag Manager by going to <b>Templates</b> -> <b>New</b> in the Tag Template box. You now select the <b>three dots</b> in the top-right corner, select <b>Import</b> and select the template.tpl file you just downloaded. You then press <b>Save</b> and now you are ready to create the tag as described below.</Text>

                </Notice>

                <Preconditions isExpanded={step == "prereq"} expand={() => setStep("prereq")}></Preconditions>
                <ListItem.Accordion nativeID="no_ads" isExpanded={step == 'no_ads'} onPress={() => setStep("no_ads")} content={
                    <>

                        <ListItem.Content>
                            <ListItem.Title><Text h2>No Ads by Adless</Text></ListItem.Title>
                        </ListItem.Content>
                    </>
                }>

                    <div style={{ height: step != "no_ads" ? 0 : 'inherit', overflowY: 'hidden' }}>

                        <Row style={{ gap: 20 }}>
                            <Column style={{ flexBasis: 500, flexGrow: 3 }}>
                                <Text>This template allows you to easily provide your content without ads to entitled subscribers and otherwise show ads using tag sequenting in GTM. We will take you through every step of the configuration.</Text>
                                <Text h3>1. Create Tag using <i>No Ads by Adless</i> Template</Text>
                                <ReactPlayer url="https://static.adless.net/vid/demo/gtm/no-ads.mp4" controls muted stopOnUnmount></ReactPlayer>
                                <Text h3>2. Fallback to Ads</Text>
                                <ReactPlayer url="https://static.adless.net/vid/demo/gtm/no-ads-fallback.mp4" controls muted stopOnUnmount></ReactPlayer>
                            </Column>
                            <Demo demo='gtm-ads'></Demo>
                        </Row>
                    </div>
                </ListItem.Accordion>
                <ListItem.Accordion nativeID="paywall" isExpanded={step == 'paywall'} onPress={() => setStep("paywall")} content={
                    <>

                        <ListItem.Content>
                            <ListItem.Title><Text h2>Paywall by Adless</Text></ListItem.Title>
                        </ListItem.Content>
                    </>
                }>
                    <Row style={{ gap: 20 }}>
                        <Column style={{ flexBasis: 500, flexGrow: 3 }}>
                            <Text>This template allows you to easily provide your content without ads to entitled subscribers and otherwise show ads using tag sequenting in GTM. We will take you through every step of the configuration.</Text>
                            <Text h3>1. Create Tag using <i>Paywall by Adless</i> Template</Text>
                        </Column>
                        <Demo demo='gtm'></Demo>
                    </Row>
                </ListItem.Accordion>
            </Section>
        </View>)
}

export default GTM;