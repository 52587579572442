
import api, { useIsLoading } from 'api';
import Demo from 'components/Demo';
import { ListItem, Notice, Section, Success, Text, View, Warning, Button, Row, Card, Column } from 'components/Layout';
import { Loading } from 'components/Loading';
import { Done, Spinner, Warning as Warn } from 'components/Symbols';
import useNavigateTo from 'hooks/useNavigateTo';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { useTranslation } from 'react-i18next';
import { Icon } from 'react-native-elements';
import ReactPlayer from 'react-player';
import { DownloadLink, ExternalLink, Link, SupportEmailLink, useSearchParams } from 'Routing';
import Preconditions from './shared';

export const Wordpress = () => {

    const { t } = useTranslation()

    const [step, setStep] = useState<"prereq" | "no_ads" | "paywall">(document.location.hash.substring(1) || "paywall")

    return (
        <View>
            <Section>
                <Text h1>Wordpress Plugin</Text>
                <Text>Adless maintains a Wordpress plugin to make integration super simple.</Text>
                <Text>The plugin is available if you search for <strong>Adless</strong> under <strong>Plugins -> Add New</strong> in your Wordpress admin panel. It is also <ExternalLink to="http://github.com/adless-tech/wordpress-plugin">available as Open-Source Software on GitHub</ExternalLink>.</Text>
                <Text>Need more details? This <ExternalLink to="https://blog.adless.net/posts/monetize-with-wordpress/?utm_source=get-started-wordpress">blog post</ExternalLink> guides you all the way!</Text>

                <Preconditions isExpanded={step == "prereq"} expand={() => setStep("prereq")}></Preconditions>

                <ListItem.Accordion nativeID="paywall" isExpanded={step == 'paywall'} onPress={() => setStep("paywall")} content={
                    <>

                        <ListItem.Content>
                            <ListItem.Title><Text h2>Paywall for Premium Content</Text></ListItem.Title>
                        </ListItem.Content>
                    </>
                }>
                    <Row style={{ gap: 20 }}>
                        <Column style={{ flexBasis: 500, flexGrow: 3 }}>
                            <Text>With Adless subscription networks, you can start offering premium content in no time! Assuming you already installed the plugin following the instructions above, this video show how you add a paywall to your posts with premium content by marking protected sections with the <i>adless-protected-content</i> css classname:</Text>

                            <Text style={{paddingVertical: 20}}>
                                <center>
                                    <ReactPlayer url="https://static.adless.net/vid/demo/wordpress/paywall.mp4" controls muted stopOnUnmount></ReactPlayer>
                                </center>
                            </Text>

                            <Text>The paywall will require a subscription for the <Link to="/earnings/PREMIUM_CONTENT">Premium Content service</Link> to access the content so you must <Link to="/earnings/PREMIUM_CONTENT">sign up to offer this service</Link> in advance. This can be modified with custom configuration.</Text>

                            <Text h3>Customize the Paywall</Text>
                            <Text>By default we show a standardized message in the visitors language or alternatively in english. You may customize the paywall to your preferences. You can do so directly from the plugin settings page:</Text>

                            <Text style={{paddingVertical: 20}}>
                                <center>
                                    <ReactPlayer url="https://static.adless.net/vid/demo/wordpress/paywall-customize.mp4" controls muted stopOnUnmount></ReactPlayer>
                                </center>
                            </Text>

                        </Column>
                    </Row>
                </ListItem.Accordion>
            </Section>
        </View>)
}

export default Wordpress;