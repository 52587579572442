
import { Card, Column } from 'components/Layout';
import React from 'react';

const Demo = ({ demo }: { demo: string }) => {
    return <Card containerStyle={{ flexBasis: 300, height: 'auto', minWidth: 300, flexGrow: 1, margin: 0, padding: 0 }}>
        <Column>
            <iframe src={'https://static.adless.net/demo-' + demo} style={{ border: 'none', minHeight: 350, height: 'inherit' }} ></iframe>
        </Column>
    </Card>
}

export default Demo;