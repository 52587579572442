
import { adlessCommand } from '.';
import { AuthorizeRequest, AuthorizeResponse, LoginRequest, LoginResponse, LogoutResponse, RegisterAccountRequest, RegisterAccountResponse, RetrieveTokenRequest, RetrieveTokenResponse } from './gen/authentication';

export default {
    login: (req: LoginRequest) => adlessCommand<LoginResponse>("/v1/auth/login", req),
    logout: () => adlessCommand<LogoutResponse>("/v1/auth/logout", {}),
    register: (req: RegisterAccountRequest) => adlessCommand<RegisterAccountResponse>("/v1/auth/register", req),
    token: (req: RetrieveTokenRequest) => adlessCommand<RetrieveTokenResponse>("/v1/auth/token", req),
    authorize: (req: AuthorizeRequest) => adlessCommand<AuthorizeResponse>("/v1/auth/authorize", req)
}