import { createBrowserHistory } from 'history';
import React from 'react';
import { Platform, TextStyle } from 'react-native';
import { Icon } from 'react-native-elements';

let LinkImpl
let LocationImpl: () => { (): any; new(): any; search: string | string[][] | Record<string, string> | URLSearchParams | undefined; pathname: string; }
let useSearchParamsImpl
if (Platform.OS === 'web') {
    let r = require('react-router-dom')
    LinkImpl = r.Link
    LocationImpl = r.useLocation
    useSearchParamsImpl = r.useSearchParams
} else {
    let r = require('react-router-native')
    LinkImpl = r.Link
    LocationImpl = r.useLocation
    useSearchParamsImpl = r.useSearchParams
}

export const Location = LocationImpl;
export const history = createBrowserHistory();
export const useSearchParams = useSearchParamsImpl;

type Props = {
    to: string | object,
    children?: any,
    style?: TextStyle,
    onPress?: () => void
}

export const Link = ({ children, style, to, onPress }: Props) => {
    return (<LinkImpl to={to} style={{color: "inherit", ...style}} onClick={onPress}>{children}</LinkImpl>)
}

export const IconLink = ({ style, to, icon }: {
    to: string | object,
    style?: TextStyle, 
    icon: string
}) => {
    return (<LinkImpl to={to} style={{color: "inherit", textDecorationLine:'none', ...style}}><Icon type='font-awesome' name={icon}></Icon></LinkImpl>)
}

export const Breadcrumb = ({ children, style, to }: Props) => {
    return (<Link to={to} style={{color: "inherit", fontSize: 24, textDecorationLine:'none', ...style}}>{children}</Link>)
}

export const ExternalLink = ({ children, style, to }: Props) => {
    return (<a href={to} target="_blank" style={{color: "inherit", ...style}}>{children}</a>)
}

export const DownloadLink = ({ children, style, href, download }: {
    children?: any,
    style?: TextStyle,
    href: string,
    download: string
}) => {
    return (<a href={href} download={download} target="_blank" style={{color: "inherit", ...style}}>{children}</a>)
}

export const EmailLink = ({ style, to }: Props) => {
    return (<a href={"mailto:" + to} target="_blank" style={{color: "inherit", ...style}}>{to}</a>)
}

type SupportEmailLinkProps = {
    style?: TextStyle,
}

export const SupportEmailLink = ({ style }: SupportEmailLinkProps) => {
    return <EmailLink style={style} to="support@adless.net"></EmailLink>
}