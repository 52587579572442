import React from 'react';
import { ActivityIndicator } from 'react-native';

import { ThemeContext } from 'react-native-elements';

export const Spinner = () => {
    const { theme } = React.useContext(ThemeContext)
    
    return <ActivityIndicator size="small" color={theme.colors?.primary} />;
}

export const Warning = () => {
    return <>⚠️</>;
}

export const Done = () => {
    return <>✅</>;
}