import { BalanceRequest, BalanceResponse, ClientRegisterRequest, ClientRegisterResponse, CreateRequest, CreateResponse, MeResponse, UpdateRequest, UpdateResponse } from 'api/gen/account';

import { adlessCommand, adlessQuery } from '.';

export default {
    me: () => adlessQuery<MeResponse>("/v1/account/me"),
    create: (req: CreateRequest) => adlessCommand<CreateResponse>("/v1/account/create", req),
    update: (req: UpdateRequest) => adlessCommand<UpdateResponse>("/v1/account/update", req),
    balance: (req: BalanceRequest) => adlessQuery<BalanceResponse>("/v1/account/balance", req),
    client: {
        register: (req: ClientRegisterRequest) => adlessCommand<ClientRegisterResponse>("/v1/account/client/register", req)
    }
}